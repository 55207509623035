import axios from "axios";
import { axiosInstance, handleErrors } from "./axiosSetup";
import { getToken } from "./auth";

export async function getTasks() {
  const token = await getToken();
  return await axiosInstance
    .get(`/tasks`, {
      headers: {
        Authorization: token,
      },
    })
    .catch(handleErrors);
}

export async function getUploadScreenshotTaskUrl(taskId, file) {
  const token = await getToken();
  return await axiosInstance.post(
    "/signed-url",
    {
      task_id: taskId,
      file_name: file,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export async function postUploadScreenshotTask(url, file) {
  const config = {
    method: "put",
    url: url,
    headers: {
      "Content-Type": "application/pdf",
    },
    data: file,
    maxBodyLength: Infinity,
  };

  return await axios.request(config);
}

export async function postSelectRorTask(taskId, selectedRorId) {
  const token = await getToken();
  return await axiosInstance.put(
    `/tasks/${taskId}`,
    {
      output: {
        selected_ror_id: selectedRorId,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export async function postConfirmRorTask(taskId, isConfirmed) {
  const token = await getToken();
  return await axiosInstance.put(
    `/tasks/${taskId}`,
    {
      output: {
        confirmed: isConfirmed,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}
