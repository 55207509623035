import React, { useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBContainer,
  MDBFile,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";

const SCI_HUB_URL = "https://sci-hub.se";

const CopyToClipboardIcon = ({ content }) => {
  const handleIconClick = () => {
    navigator.clipboard.writeText(content);
    toast("Link successfully copied to clipboard", {
      type: "info",
      autoClose: 2000,
    });
  };

  return (
    <MDBIcon
      className="ms-2"
      key={content}
      size="lg"
      icon="copy"
      onClick={handleIconClick}
      style={{ cursor: "pointer" }}
    />
  );
};

export const UploadScreenshotCard = ({ task, onUploadScreenshot }) => {
  const [upload, setUpload] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setUpload(file);
    } else {
      toast("Please upload an image file", {
        type: "warning",
      });
    }
  };

  const handleSubmit = () => {
    if (!upload) {
      return;
    }
    onUploadScreenshot(upload);
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBRow className="mb-3">
          <MDBContainer className="text-start">
            <MDBListGroup light numbered>
              <MDBListGroupItem>
                Go to <b>SCI-HUB</b>
                <CopyToClipboardIcon content={SCI_HUB_URL} />
              </MDBListGroupItem>
              <MDBListGroupItem>
                Search for <b>{task.data?.url}</b>
                <CopyToClipboardIcon content={task.data?.url} />
              </MDBListGroupItem>
              <MDBListGroupItem>Make a screenshot</MDBListGroupItem>
              <MDBListGroupItem>Upload it</MDBListGroupItem>
            </MDBListGroup>
          </MDBContainer>
        </MDBRow>
        <MDBFile
          onChange={handleFileChange}
          label="Upload screenshot"
          size="lg"
          id="formFileLg"
        />
      </MDBCardBody>
      <MDBCardFooter>
        <div className="d-flex justify-content-center">
          <MDBBtn disabled={!upload} color="success" onClick={handleSubmit}>
            Accept
          </MDBBtn>
        </div>
      </MDBCardFooter>
    </MDBCard>
  );
};
