import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { getUserPool } from "../helpers/auth";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const navigate = useNavigate();
  const userPool = getUserPool();

  const checkAuthState = useCallback(() => {
    const handleNavigation = (authStatus) => {
      setIsAuthenticated(authStatus);
      navigate(authStatus ? "/" : "/login");
    };

    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session) => {
        handleNavigation(!err && session.isValid());
      });
    } else {
      handleNavigation(false);
    }
  }, [navigate, userPool]);

  useEffect(() => {
    checkAuthState();
  }, [checkAuthState, isAuthenticated]);

  const login = async (email, password) => {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: email,
        Password: password,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const user = new CognitoUser(userData);

      user.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          setIsAuthenticated(true);
          resolve();
        },
        onFailure: (err) => {
          toast(err.message, { type: "error" });
          setIsAuthenticated(false);
          resolve();
        },
        newPasswordRequired: () => {
          setCognitoUser(user);
          setIsNewPasswordRequired(true);
          resolve();
        },
      });
    });
  };

  const setNewPassword = async (newPassword) => {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, [], {
        onSuccess: () => {
          setIsAuthenticated(true);
          setIsNewPasswordRequired(false);
          setCognitoUser(null);
          resolve();
        },
        onFailure: (err) => {
          toast(err.message, { type: "error" });
          resolve();
        },
      });
    });
  };

  const logout = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isNewPasswordRequired,
        login,
        logout,
        setNewPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
