import React, { useContext, useState } from "react";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBIcon,
} from "mdb-react-ui-kit";
import { AuthContext } from "../../context/AuthContext";

export const Login = () => {
  const { login, setNewPassword, isNewPasswordRequired } =
    useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    newPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(credentials.email, credentials.password);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await setNewPassword(credentials.newPassword);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MDBContainer className="p-5 mt-5 d-flex flex-column align-items-center">
      <div
        className="card"
        style={{
          width: "24rem",
          padding: "2rem",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        <div className="mb-4 text-center">
          <MDBIcon
            fas
            icon="user-circle"
            size="3x"
            className="mb-3 text-muted"
          />
        </div>

        <form
          onSubmit={
            isNewPasswordRequired ? handleNewPasswordSubmit : handleLogin
          }
        >
          <MDBInput
            wrapperClass="mb-4"
            className="active"
            label="Username"
            title="Username"
            id="form1"
            name="email"
            onChange={handleChange}
            required
            disabled={isNewPasswordRequired}
          />

          {isNewPasswordRequired ? (
            <>
              <MDBInput
                wrapperClass="mb-4"
                label="New Password"
                className="active"
                id="form2"
                type="password"
                name="newPassword"
                onChange={handleChange}
                required
              />
              <MDBBtn
                color="secondary"
                type="submit"
                className="mb-4 w-100"
                disabled={isLoading}
              >
                {isLoading && <MDBSpinner size="sm" className="primary me-2" />}
                Set New Password
              </MDBBtn>
            </>
          ) : (
            <>
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                className="active"
                id="form2"
                type="password"
                name="password"
                onChange={handleChange}
                required
              />
              <MDBBtn type="submit" className="mb-4 w-100" disabled={isLoading}>
                {isLoading && <MDBSpinner size="sm" className="primary me-2" />}
                Sign in
              </MDBBtn>
            </>
          )}
        </form>
      </div>
    </MDBContainer>
  );
};
