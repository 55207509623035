import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBSpinner,
  MDBTypography,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";

import {
  getTasks,
  postSelectRorTask,
  postConfirmRorTask,
  postUploadScreenshotTask,
  getUploadScreenshotTaskUrl,
} from "../../helpers";
import {
  UploadScreenshotCard,
  ConfirmRorCard,
  SelectRorCard,
} from "./components";

const TASK_TYPES = {
  select_ror: "select_ror",
  confirm_ror: "confirm_ror",
  upload_screenshot: "screenshot",
};

const taskNameMap = {
  [TASK_TYPES.confirm_ror]: "Confirm ROR",
  [TASK_TYPES.select_ror]: "Select ROR",
  [TASK_TYPES.upload_screenshot]: "Upload screenshot",
};

export const Dashboard = () => {
  const [tasks, setTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await getTasks();
      setTasks(response.data.tasks);
    } catch (error) {
      toast("Error fetching tasks", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTaskAction = async (taskType, taskId, payload) => {
    setIsLoading(true);

    try {
      switch (taskType) {
        case TASK_TYPES.select_ror:
          await postSelectRorTask(taskId, payload);
          break;
        case TASK_TYPES.confirm_ror:
          await postConfirmRorTask(taskId, payload);
          break;
        case TASK_TYPES.upload_screenshot:
          const response = await getUploadScreenshotTaskUrl(
            taskId,
            payload?.name
          );
          const formData = new FormData();
          formData.append("file", payload);
          await postUploadScreenshotTask(response.data.url, formData);
          break;
        default:
          throw new Error("Invalid task type");
      }
      toast("Task completed successfully", {
        type: "success",
        autoClose: 1000,
      });
    } catch (error) {
      toast("Error performing task action", { type: "error" });
    } finally {
      setIsLoading(false);
      fetchTasks();
    }
  };

  const currentTask = tasks?.length > 0 ? tasks[0] : null;
  const currentTaskName = taskNameMap[currentTask?.type] || "";

  return (
    <MDBContainer className="justify-content-center mt-5">
      {isLoading && <MDBSpinner className="mt-5" />}
      {!isLoading && !currentTask && (
        <MDBRow className="justify-content-center mt-5 mb-3">
          <h2 className="text-center display-6">
            There are no incomplete tasks at the moment
          </h2>
        </MDBRow>
      )}
      {!isLoading && currentTask && (
        <div>
          <MDBRow className="justify-content-center">
            <MDBCol className="mb-4" md="10" lg="8" sm="12">
              <MDBTypography
                note
                noteColor="primary"
                className="d-flex justify-content-between pb-0 mb-0"
              >
                <MDBTypography>{currentTaskName}</MDBTypography>
                <MDBTypography>
                  <MDBIcon className="ms-2" fas icon="list-check" size="lg" />
                  <MDBBadge color="danger" pill>
                    {tasks?.length > 10 ? "10+" : tasks.length}
                  </MDBBadge>
                </MDBTypography>
              </MDBTypography>
              <TaskCard task={currentTask} onTaskAction={handleTaskAction} />
            </MDBCol>
          </MDBRow>
        </div>
      )}
    </MDBContainer>
  );
};

const TaskCard = ({ task, onTaskAction }) => {
  const handleAction = (payload) => {
    onTaskAction(task.type, task.id, payload);
  };

  switch (task.type) {
    case TASK_TYPES.select_ror:
      return <SelectRorCard task={task} onSelectRor={handleAction} />;
    case TASK_TYPES.confirm_ror:
      return <ConfirmRorCard task={task} onConfirmRor={handleAction} />;
    case TASK_TYPES.upload_screenshot:
      return (
        <UploadScreenshotCard task={task} onUploadScreenshot={handleAction} />
      );
    default:
      return null;
  }
};
