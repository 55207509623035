import React, { useContext } from 'react';
import { MDBNavbar, MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import { AuthContext } from '../../context/AuthContext';

export const Navbar = () => {
    const { logout, isAuthenticated } = useContext(AuthContext)

    return (
        <MDBNavbar dark bgColor='primary'>
            <MDBContainer fluid>
                <a href="/" className="navbar-brand">HIT</a>
                {isAuthenticated &&
                    <div className='d-flex'>
                        <MDBBtn onClick={logout} color="dark" size="md">Log Out</MDBBtn>
                    </div>
                }
            </MDBContainer>
        </MDBNavbar>
    );
};

