import './App.css';

import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';

import { Navbar } from './components';
import { Login, Dashboard } from './pages';
import { AuthContext, AuthProvider } from './context/AuthContext';


const PrivateRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  )
}

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Dashboard />} path="/" exact />
            </Route>
            <Route element={<Login />} path="/login" />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
