import { CognitoUserPool } from "amazon-cognito-identity-js";
import { CognitoClientId, CognitoUserPoolId } from "../constants";

const userPool = new CognitoUserPool({
    UserPoolId: CognitoUserPoolId,
    ClientId: CognitoClientId,
});

export const getUserPool = () => {
    return userPool;
}


export const getToken = () => {
    return new Promise((resolve, reject) => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }

                resolve(session.getIdToken().getJwtToken());
            });
        } else {
            reject(new Error('No user is logged in'));
        }
    });
}
