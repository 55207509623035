import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBCol,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

const BORDER_COLORS = ["primary", "warning", "info"];

const RorOption = ({ option, onSelectRor, borderColor }) => (
  <MDBCard border={borderColor}>
    <MDBCardBody className="text-start">
      <MDBRow>
        <MDBCol lg={10} md={10}>
          <MDBTypography variant="h5" className="fw-bolder">
            {option?.ror_name}, {option?.country}
          </MDBTypography>
          <div>
            <a
              href={option?.ror_id}
              target="_blank"
              rel="noreferrer"
              aria-label={`Link to ${option?.ror_name}`}
            >
              {option?.ror_id}
            </a>
          </div>
        </MDBCol>
        <MDBCol>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ height: "100%" }}
          >
            <MDBBtn
              color={borderColor}
              onClick={() => onSelectRor(option.ror_id)}
            >
              Select
            </MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCardBody>
  </MDBCard>
);

export const SelectRorCard = ({ task, onSelectRor }) => (
  <MDBCard className="mb-5">
    <MDBCardHeader>
      <MDBCardTitle className="lead mt-1">
        Select the matching ROR record
      </MDBCardTitle>
    </MDBCardHeader>
    <MDBCardBody>
      <div className="text-start">
        <MDBTypography variant="h5">Affiliation text:</MDBTypography>
        <MDBTypography variant="h5" className="fw-bolder">
          {task.data?.affiliation}
        </MDBTypography>
      </div>
      <MDBRow className="g-2">
        {task.data?.options?.map((option, index) => (
          <RorOption
            key={option.ror_id}
            option={option}
            onSelectRor={onSelectRor}
            borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
          />
        ))}
        <MDBCard border="danger">
          <MDBCardBody className="text-start">
            <MDBRow>
              <MDBCol md={9}>
                <MDBTypography variant="h5" className="fw-bolder">
                  None of these options
                </MDBTypography>
              </MDBCol>
              <MDBCol>
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ height: "100%" }}
                >
                  <MDBBtn color="danger" onClick={() => onSelectRor(null)}>
                    Select
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBCardBody>
  </MDBCard>
);
