import axios from "axios";
import { ApiBaseUrl } from "../constants";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
});

export function handleErrors(error) {
  toast(error.message, { type: "error" });
  return Promise.reject(error);
}

export { axiosInstance };
