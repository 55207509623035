import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

export const ConfirmRorCard = ({ task, onConfirmRor }) => {
  const AffiliationInfo = () => (
    <>
      <MDBTypography className="my-2">Affiliation text</MDBTypography>
      <MDBTypography variant="h5" className="fw-bolder text-start">
        {task.data?.affiliation}
      </MDBTypography>
    </>
  );

  return (
    <MDBCard className="mb-5">
      <MDBCardHeader>
        <AffiliationInfo />
      </MDBCardHeader>
      <MDBCardBody>
        <MDBTypography className="mb-3">Matched ROR record</MDBTypography>
        <MDBRow className="text-start">
          <MDBTypography variant="h5" className="fw-bolder">
            {task.data?.ror_name}, {task.data?.country}
          </MDBTypography>
          <div>
            <a
              href={task.data?.ror_id}
              target="_blank"
              rel="noreferrer"
              aria-label={`Link to ${task.data?.ror_name}`}
            >
              {task.data?.ror_id}
            </a>
          </div>
        </MDBRow>
      </MDBCardBody>
      <MDBCardFooter>
        <div className="d-flex justify-content-center">
          <MDBBtn
            onClick={() => onConfirmRor(true)}
            className="mx-2"
            color="success"
          >
            Confirm
          </MDBBtn>
          <MDBBtn
            onClick={() => onConfirmRor(false)}
            className="mx-2"
            color="danger"
          >
            False positive
          </MDBBtn>
        </div>
      </MDBCardFooter>
    </MDBCard>
  );
};
